<template>
  <div style="width: 100%;overflow: hidden">
    <el-main id="recharge-div" style="font-size: 14px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #666666;">
      <div class="froms" style="float: left;    margin-top: -10px;
    margin-bottom: 10px;">
        <span style="font-size: 14px;font-family: Adobe Heiti Std;
font-weight: normal;
color: #666666;">统计时间：</span>
        <el-date-picker
          v-model="value2"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right">
        </el-date-picker>
        <span @click="search" style="cursor: pointer;
           margin-left: 12px;
    border-radius: 5px;
    padding: 9px 25px;
    background-color:#184482FF;font-size: 16px;
font-family: Adobe Heiti Std;
font-weight: normal;
color: #FFFFFF;">查询</span>
      </div>
      <div>
        <el-table
          @sort-change='sortTableFun'
          :row-class-name="tableRowClassName"
          :header-cell-style="{ 'background': '#d3dee8','font-weight': '400','color': '#666666','font-family':'Source Han Sans CN'}"
          :data="tableData"
          style="width: 100%;">
          <el-table-column
            align="center"
            type="index"
            label="序号"
            width="80"
            :index="indexMethod">
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            align="center"
            prop="userId"
            label="子账号ID"
            width="180">
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            sortable
            align="center"
            prop="count"
            label="翻译文档数">
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            align="center"
            prop="sum"
            label="页数">
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: right;margin-top: 20px;" v-if="listTotal!==0&&listTotal>10">
        <el-pagination
          background
          layout="prev, pager, next"
          :page-size="10"
          :total="listTotal"
          @prev-click="prevClick"
          @next-click="nextClick"
          @current-change="currentChange"
        >
        </el-pagination>
      </div>
    </el-main>
  </div>
</template>

<script>
  export default {
    name: "Count",
    data() {
      return {
        value2: '',
        tableData: [],
        userId: '',
        userName: '',
        loginModel: '',
        ipScope: '',
        powerType: '',
        valTime: '',
        isTrial: false,
        isFreeze: false,
        balance: 0,
        dayCount: 0,
        dayCountRemain: 0,
        childTranslateCountDay: 0,
        listTotal: 0,
        currentPage: 0,
        pageSize: 10,
        sort: 0,
        start: '',
        end: ''
      }
    },
    created() {
      this.getRechargeOrder(0, 10, this.sort)
    },
    methods: {
      search() {
        debugger
        if (!this.value2) {
          this.getRechargeOrder(0, 10, this.sort)
          return
        }
        const start = this.value2[0];
        const end = this.value2[1];
        this.start = start.getFullYear() + "-" + (start.getMonth()+1) + "-" + start.getDate() + " 00:00:00";
        this.end = end.getFullYear() + "-" + (end.getMonth()+1) + "-" + end.getDate() + " 23:59:59";
        this.getRechargeOrder(0, 10, this.sort)
      },
      sortTableFun(column) {
        if (column.prop) {
          if (column.order === 'ascending') {
            this.sort = 1
            this.getRechargeOrder(0, 10, this.sort)
          } else if (column.order === 'descending') {
            this.sort = 0
            this.getRechargeOrder(0, 10, this.sort)
          }
        }
      },
      indexMethod(index) {
        if (this.currentPage === 0) {
          return (this.currentPage) * this.pageSize + index + 1;
        }
        return (this.currentPage - 1) * this.pageSize + index + 1;
      },
      nextClick(val) {
        this.getRechargeOrder(val, 10, this.sort)
      },
      prevClick(val) {
        this.getRechargeOrder(val, 10, this.sort)
      },
      currentChange(val) {
        this.getRechargeOrder(val, 10, this.sort)
      },
      getRechargeOrder(currentPage, pageSize, sort) {
        this.currentPage = currentPage
        this.pageSize = pageSize
        const  date=new Date();
        const that = this;
        this.$http.post('/api/count', {
          "currentPage": currentPage,
          "pageSize": pageSize,
          "sort": sort,
          "start": that.start===''?"2020-01-01 00:00:00":that.start,
          "end": that.end===''?date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate() + " 23:59:59":that.end,
        }).then(function (response) {
          if (response.data) {
            that.listTotal = response.data.totalCount;
            that.tableData = [];
            response.data.users.forEach((item, index) => {
              const data = {}
              data.userId = item.userId
              data.sum = item.sum
              data.count = item.count
              that.tableData.push(data)
            });
          }
        }).catch(function (error) {
        });
      },
      tableRowClassName({row, rowIndex}) {
        if (rowIndex % 2 === 0) {
          return 'warning-row';
        } else {
          return 'success-row';
        }
        return '';
      }
    }
  }
</script>
<style>
  .el-table td {
    padding: 7px 0;
  }
  .froms .el-input__inner {
    background-color: #F5F5F5FF;
    border-color:  #F5F5F5FF;
  }
  .froms .el-range-input {
    background-color: #F5F5F5FF;
  }
  .el-table .warning-row {
    background: #FFFFFFFF;
  }

  .el-table .success-row {
    background: #F2F5F8FF;
  }
</style>
<style scoped>

  #recharge-div-one {
    background: #FFFFFF;
    overflow: hidden;
    margin-bottom: 20px;
  }

  #recharge-div {
    background: #FFFFFF;
    overflow: hidden;
    height: 760px;
  }

  #record-div {
    margin-top: 20px;
    height: 700px !important;
    background: #FFFFFF;
  }

  .title-font {
    width: 96px;
    height: 24px;
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #949494;
    margin-top: 27px;
  }

  .line-div {
    width: 98px;
    height: 1px;
    background: #DDDDDD;
  }

  #select-div {
    height: 211px;
    /*width: 890px;*/
    /*border: 1px solid #DDDDDD;*/
  }

  .select-frame {
    float: left;
    margin-left: 42px;
    width: 150px;
    height: 180px;
    background-color: white;
    border: 2px solid #DFB465;
    border-radius: 10px;
    cursor: pointer;
  }

  .select-frame-active {
    background: #fcf5eb !important;
  }

  .select-font-1 {
    height: 25px;
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #27314B;
    margin-top: 15px;
  }

  .select-font-2 {
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #DFB465;
    text-align: center;
    padding: 20px;
  }

  .select-font-3 {
    height: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #8F94A7;
    text-align: center;
  }
</style>
