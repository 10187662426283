<template>
  <div style="width: 100%;overflow: hidden">
    <el-main id="recharge-div" style="font-size: 14px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #666666;">
      <div style="float: left;margin-top: 0px;margin-bottom: 20px;margin-left: -11px;">
        <span @click="dialogFormVisible=true" style="cursor: pointer;
           margin-left: 12px;
    border-radius: 5px;
    padding: 9px 25px;
    background-color:#184482FF;font-size: 16px;
font-family: Adobe Heiti Std;
font-weight: normal;
color: #FFFFFF;">添加术语</span>
        <span @click="dialogFormFileVisible=true" style="cursor: pointer;
           margin-left: 12px;
    border-radius: 5px;
    padding: 9px 25px;
    background-color:#184482FF;font-size: 16px;
font-family: Adobe Heiti Std;
font-weight: normal;
color: #FFFFFF;">批量添加</span>
      </div>
      <div>
        <el-table
          :row-class-name="tableRowClassName"
          :header-cell-style="{ 'background': '#d3dee8','font-weight': '400','color': '#666666','font-family':'Source Han Sans CN'}"
          :data="tableData"
          style="width: 100%;">
          <el-table-column
            align="center"
            type="index"
            label="序号"
            width="50"
            :index="indexMethod">
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            align="center"
            prop="origin"
            label="原词"
            width="150">
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            align="center"
            prop="tager"
            label="目标词"
            width="150">
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            align="center"
            prop="fromto"
            label="翻译方向">
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            align="center"
            prop="createTime"
            label="添加时间">
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            align="center"
            prop="option"
            label="操作">
            <template slot-scope="scope">
              <el-button @click="handleDelectClick(scope.row.id)" type="text" size="small" slot="reference" style="font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #666666;">删除
              </el-button>
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            align="center"
            prop="add"
            label="添加人">
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: right;margin-top: 20px;" v-if="listTotal!==0&&listTotal>10">
        <el-pagination
          background
          layout="prev, pager, next"
          :page-size="10"
          :total="listTotal"
          @prev-click="prevClick"
          @next-click="nextClick"
          @current-change="currentChange"
        >
        </el-pagination>
      </div>
    </el-main>
    <el-dialog :before-close="handleClose2" class="fromDialog" title="添加术语" :visible.sync="dialogFormVisible" width="600px">
      <el-form class="froms" :model="form" :rules="form_rules" ref="formRef"
               size="font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #333333;">
        <span style="float: left;padding: 10px; margin-left: 28px;">翻译方向：</span>
        <el-form-item prop="from" style="float: left;width: 180px;margin-right: 15px;">
          <template>
            <el-select
              class="borderSelect"
              v-model="form.from"
              filterable
              placeholder="原文语言"
            >
              <el-option-group
                v-for="group in lanuagesFrom"
                :key="group.value"
                :label="group.label"
              >
                <el-option
                  v-for="item in group.lanuagesFrom"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-option-group>
            </el-select>
          </template>
        </el-form-item>
        <el-form-item prop="to" style="float: left;width: 180px;">
          <template>
            <el-select
              class="borderSelect"
              v-model="form.to"
              filterable
              placeholder="目标语言"
            >
              <el-option-group
                v-for="group in lanuagesTo"
                :key="group.value"
                :label="group.label"
              >
                <el-option
                  v-for="item in group.lanuagesFrom"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-option-group>
            </el-select>
          </template>
        </el-form-item>
        <span style="float: left;padding: 10px;margin-left: 55px;">原词：</span>
        <el-form-item prop="original" style="float: left;width: 377px;margin-right: 30px;">
          <el-input v-model="form.original" autocomplete="off"></el-input>
        </el-form-item>
        <span style="float: left;padding: 10px;margin-left: 40px;">目标词：</span>
        <el-form-item prop="targer" style="float: left;width: 377px;">
          <el-input v-model="form.targer" autocomplete="off"></el-input>
        </el-form-item>
        <span style="float: left;padding: 10px;margin-left: 40px;">添加人：</span>
        <el-form-item style="float: left;width: 377px;">
          <el-input v-model="form.addUserName" autocomplete="off"></el-input>
        </el-form-item>
        <span style="float: left;padding: 10px;margin-left: 26px;">双向添加：</span>
        <el-form-item style="float: left;width: 377px;text-align: left;">
          <el-checkbox v-model="form.checked"></el-checkbox>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button style="background: rgb(24, 68, 130);
          border-radius: 2px;
          border-color: rgb(24, 68, 130);
          width: 120px;
          font-size: 14px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #FFFFFF;" type="primary" @click="add">添加
        </el-button>
      </div>
    </el-dialog>
    <el-dialog :before-close="handleClose" class="fromDialog fromBatchDialog" title="批量添加"
               :visible.sync="dialogFormFileVisible" width="600px">
      <el-form class="fromFile" :model="formFile" :rules="form_file_rules" ref="formRefFile"
               size="font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #333333;">
        <span style="float: left;padding: 10px;margin-left: 0px;">文件类型：</span>
        <el-form-item style="float: left;width: 377px;margin-right: 10px;">
          <el-input value="TXT文件" autocomplete="off"></el-input>
        </el-form-item>
        <span style="float: left;padding: 10px;margin-left: 0px;">文件编码：</span>
        <el-form-item style="float: left;width: 377px;margin-right: 10px">
          <el-input value="UTF-8" autocomplete="off"></el-input>
        </el-form-item>
        <span style="float: left;padding: 10px;margin-left: 0px;">文件格式：</span>
        <el-form-item style="float: left;width: 377px;margin-right: 10px">
          <el-input value="原词与目标词用Tab键隔开" autocomplete="off"></el-input>
        </el-form-item>
        <span style="float: left;padding: 10px;margin-left: 0px;">文件大小：</span>
        <el-form-item style="float: left;width: 377px;margin-right: 10px">
          <el-input value="最大2M" autocomplete="off"></el-input>
        </el-form-item>
        <span style="float: left;padding: 10px;margin-left: 28px;margin-right: 15px;">示例：</span>
        <el-form-item style="float: left;width: 377px;margin-right: 20px">
          <el-collapse accordion>
            <el-collapse-item class="langeuage" title="东北大学NEU" name="1">
              <div>麻省理工学院 MIT</div>
              <div>斯坦福大学 Stanford</div>
              <div>耶鲁大学 Yale</div>
              <div>哈佛大学 Harvard</div>
            </el-collapse-item>
          </el-collapse>
        </el-form-item>
        <span style="float: left;padding: 10px;">翻译方向：</span>
        <el-form-item class="langeuage" prop="from"
                      style="float: left;width: 180px;margin-right: 15px;margin-left: 15px;">
          <template>
            <el-select
              class="borderSelect"
              v-model="formFile.from"
              filterable
              placeholder="原文语言"
            >
              <el-option-group
                v-for="group in lanuagesFrom"
                :key="group.value"
                :label="group.label"
              >
                <el-option
                  v-for="item in group.lanuagesFrom"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-option-group>
            </el-select>
          </template>
        </el-form-item>
        <el-form-item class="langeuage" prop="to" style="float: left;width: 180px;">
          <template>
            <el-select
              class="borderSelect"
              v-model="formFile.to"
              filterable
              placeholder="目标语言"
            >
              <el-option-group
                v-for="group in lanuagesTo"
                :key="group.value"
                :label="group.label"
              >
                <el-option
                  v-for="item in group.lanuagesFrom"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-option-group>
            </el-select>
          </template>
        </el-form-item>
        <span style="float: left;padding: 10px;margin-left: 15px;margin-right: 15px;">添加人：</span>
        <el-form-item class="langeuage" style="float: left;width: 377px;">
          <el-input v-model="formFile.addUserName" autocomplete="off"></el-input>
        </el-form-item>
        <span style="float: left;padding: 10px;margin-left: 5px;margin-right: 15px;">双向添加：</span>
        <el-form-item style="float: left;width: 377px;text-align: left;margin-right: 15px;">
          <el-checkbox v-model="formFile.checked"></el-checkbox>
        </el-form-item>
        <el-upload
          ref="upload"
          :on-success="onSuccess"
          :before-upload="beforeUpload"
          :on-change="beforeUpload"
          :on-error="onError"
          :on-exceed="onExceed"
          :auto-upload="false"
          :data="formFile"
          :limit="10"
          accept=".txt"
          class="upload-demo"
          drag
          action="api/files"
          multiple>
          <div class="el-upload__text">拖入/选择要上传的文件</div>
        </el-upload>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button style="background: rgb(24, 68, 130);
          border-radius: 2px;
          border-color: rgb(24, 68, 130);
          width: 120px;
          font-size: 14px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #FFFFFF;" type="primary" @click="addFile">添加
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import lanuagefrom from "../../languagefrom.json";
  import languageto from "../../languageto.json";
  import languages from "../../language.json";

  export default {
    name: "Map",
    data() {
      return {
        visible: false,
        languages: languages,
        lanuagesFrom: lanuagefrom,
        lanuagesTo: languageto,
        form: {
          from: '',
          to: '',
          original: '',
          targer: '',
          addUserName: '机构管理员',
          checked: false,
        },
        formFile: {
          from: '',
          to: '',
          addUserName: '机构管理员',
          checked: false,
        },
        form_file_rules: {
          from: [
            {required: true, message: '请输入原语言', trigger: 'blur'},
          ],
          to: [
            {required: true, message: '请输入目标语言', trigger: 'blur'},
          ],
        },
        form_rules: {
          from: [
            {required: true, message: '请输入原语言', trigger: 'blur'},
          ],
          to: [
            {required: true, message: '请输入目标语言', trigger: 'blur'},
          ],
          original: [
            {required: true, message: '请输入原词', trigger: 'blur'},
          ],
          targer: [
            {required: true, message: '请输入目标词', trigger: 'blur'},
          ]
        },
        dialogFormVisible: false,
        dialogFormFileVisible: false,
        tableData: [],
        listTotal: 0,
        currentPage: 0,
        pageSize: 10,
        start: '',
        end: ''
      }
    },
    created() {
      this.getRechargeOrder(0, 10)
    },
    methods: {
      handleClose2(done) {
        done();
        this.form.from = ''
        this.form.to = ''
        this.form.original = ''
        this.form.targer = ''
        this.form.addUserName = '机构管理员'
        this.form.checked = false
      },
      handleClose(done) {
        done();
        this.formFile.from = '';
        this.formFile.to = '';
        this.formFile.addUserName = '机构管理员';
        this.formFile.checked = false;
        this.$refs.upload.clearFiles()
      },
      handleDelectClick(id) {
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.get("/api/delMap?id=" + id).then(res => {
            if (res.data) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.getRechargeOrder(0, 10)
              return
            }
            this.$message({
              type: 'error',
              message: '删除失败!'
            });
          }).catch(err => {
            this.$message({
              type: 'error',
              message: '删除失败!'
            });
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      onSuccess(response, file, fileList) {
        this.dialogFormFileVisible = false
        if (!response) {
          this.$message.error("添加失败，请检查您上传的文档！");
          return;
        }
        this.formFile.from = ''
        this.formFile.to = ''
        this.formFile.addUserName = '机构管理员'
        this.formFile.checked = false
        this.$message.success("添加成功");
        this.$refs.upload.clearFiles()
        this.getRechargeOrder(0, 10)
      },
      addFile() {
        if (this.$refs.upload.uploadFiles.length === 0) {
          this.$message.error("请选择文件");
          return;
        }
        const that = this;
        this.$refs.formRefFile.validate((valid) => {
          if (!valid) {
            return;
          }
          that.$refs.upload.submit();
        })
      },
      beforeUpload(file) {
        const fileName = file.name;
        const fileType = fileName.substring(fileName.lastIndexOf('.'));
        if (fileType !== '.txt') {
          this.$message.error("上传非txt文件");
          return false;
        }
        if (file.size > 2 * 1024 * 1024) {
          this.$message.error("上传txt文件超过2M");
          return false;
        }
        return true;
      },
      onError(err, file, fileList) {
        this.$message.error("文件上传失败");
      },
      onExceed(files, fileList) {
        this.$message.error("最大只支持一次性上传10个文件");
      },
      add() {
        this.$refs.formRef.validate((valid) => {
          if (!valid) {
            return;
          }
          const that = this;
          this.$http.post('/api/addMap', {
            "from": that.form.from,
            "to": that.form.to,
            "original": that.form.original,
            "targer": that.form.targer,
            "addUserName": that.form.addUserName,
            "checked": that.form.checked
          }).then(res => {
            if (res.data) {
              that.dialogFormVisible = false
              that.$message.success("添加成功");
              that.getRechargeOrder(0, 10)
              that.form.from = ''
              that.form.to = ''
              that.form.original = ''
              that.form.targer = ''
              that.form.addUserName = '机构管理员'
              that.form.checked = false
              return
            }
            that.$message.error("添加失败");
          }).catch(err => {
            that.$message.error("添加异常");
          })
        });
      },
      indexMethod(index) {
        if (this.currentPage === 0) {
          return (this.currentPage) * this.pageSize + index + 1;
        }
        return (this.currentPage - 1) * this.pageSize + index + 1;
      },
      nextClick(val) {
        this.getRechargeOrder(val, 10)
      },
      prevClick(val) {
        this.getRechargeOrder(val, 10)
      },
      currentChange(val) {
        this.getRechargeOrder(val, 10)
      },
      getRechargeOrder(currentPage, pageSize) {
        this.currentPage = currentPage
        this.pageSize = pageSize
        const that = this;
        this.$http.post('/api/maps', {
          "currentPage": currentPage,
          "pageSize": pageSize,
        }).then(function (response) {
          if (response.data) {
            that.listTotal = response.data.totalCount;
            that.tableData = [];
            response.data.users.forEach((item, index) => {
              const data = {}
              data.id = item.id
              data.origin = item.original
              data.tager = item.targer
              data.fromto = that.getChineseName(item.from) + "-" + that.getChineseName(item.to)
              data.createTime = item.addTime
              data.add = item.addUserName
              that.tableData.push(data)
            });
          }
        }).catch(function (error) {
        });
      },
      getChineseName(lanuage) {
        let label = lanuage;
        this.languages.forEach((item) => {
          if (item.value === lanuage) {
            label = item.label;
          }
        })
        return label;
      },
      tableRowClassName({row, rowIndex}) {
        if (rowIndex % 2 === 0) {
          return 'warning-row';
        } else {
          return 'success-row';
        }
        return '';
      }
    }
  }
</script>
<style>
  .el-table td {
    padding: 7px 0;
  }

  .el-dialog__body {
    padding-bottom: 0;
  }

  .el-upload-dragger {
    height: 60px !important;
  }

  .el-upload__text {
    line-height: 60px !important;;
    font-size: 18px !important;;
    font-family: Microsoft YaHei !important;;
    font-weight: 400 !important;;
    color: #194687 !important;;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #184482;
    border-color: #184482;
  }

  .langeuage .el-collapse-item__content {
    text-align: left;
    padding-left: 15px;
    background-color: #F5F5F5FF;
  }

  .langeuage .el-collapse-item__header {
    background-color: #F5F5F5FF;
    border-color: #F5F5F5FF;
    height: 40px;
  }

  .langeuage .el-collapse-item__header {
    padding-left: 15px;
  }

  .fromDialog .el-dialog__title {
    font-size: 18px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #333333;
    line-height: 30px;
    float: left;
    padding: 0 15px;
  }

  .fromDialog .el-dialog__header {
    padding: 10px 30px 40px;
    border-bottom: 1px #dddddd solid;
  }

  .froms .el-input__inner {
    background-color: #F5F5F5FF;
    border-color: #F5F5F5FF;
  }

  .fromFile .el-input__inner {
    background-color: #ffffff;
    border-color: #ffffff;
  }

  .langeuage .el-input__inner {
    background-color: #F5F5F5FF;
    border-color: #F5F5F5FF;
  }

  .el-table .warning-row {
    background: #FFFFFFFF;
  }

  .el-table .success-row {
    background: #F2F5F8FF;
  }
</style>
<style scoped>
  #recharge-div-one {
    background: #FFFFFF;
    overflow: hidden;
    margin-bottom: 20px;
  }

  #recharge-div {
    background: #FFFFFF;
    overflow: hidden;
    height: 760px;
  }

  #record-div {
    margin-top: 20px;
    height: 700px !important;
    background: #FFFFFF;
  }

  .title-font {
    width: 96px;
    height: 24px;
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #949494;
    margin-top: 27px;
  }

  .line-div {
    width: 98px;
    height: 1px;
    background: #DDDDDD;
  }

  #select-div {
    height: 211px;
    /*width: 890px;*/
    /*border: 1px solid #DDDDDD;*/
  }

  .select-frame {
    float: left;
    margin-left: 42px;
    width: 150px;
    height: 180px;
    background-color: white;
    border: 2px solid #DFB465;
    border-radius: 10px;
    cursor: pointer;
  }

  .select-frame-active {
    background: #fcf5eb !important;
  }

  .select-font-1 {
    height: 25px;
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #27314B;
    margin-top: 15px;
  }

  .select-font-2 {
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #DFB465;
    text-align: center;
    padding: 20px;
  }

  .select-font-3 {
    height: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #8F94A7;
    text-align: center;
  }
</style>
