<template>
  <div style="width: 100%;overflow: hidden">
    <el-main id="recharge-div" style="font-size: 14px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #666666;">
      <div style="font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 800;
    color: #666666;
    width: 100%;
    text-align: left;
    margin-top: 17px;
    margin-left: 22px;">{{userId}}
      </div>
      <div style="width: 100%;text-align: left;height: 53px;line-height: 70px;margin-left: 22px;">
        <span style="background-color: #F5F5F5;padding: 6px 66px;">登录方式</span>
        <span style="text-align: left;margin-left: 30px">{{loginModel}}</span>
      </div>
      <div style="width: 100%;text-align: left;height: 53px;line-height: 70px;margin-left: 22px;">
        <span style="background-color: #F5F5F5;padding: 6px 66px;">账号类型</span>
        <span style="text-align: left;margin-left: 30px">{{powerType==='GBalance'?'余额':'限时'}}</span>
      </div>
      <div v-if="powerType==='GBalance'&&!isTrial"
           style="width: 100%;text-align: left;height: 53px;line-height: 70px;margin-left: 22px;">
        <span style="background-color: #F5F5F5;padding: 6px 80px;">余额</span>
        <span style="text-align: left;margin-left: 30px">{{balance}}</span>
      </div>
      <div style="width: 100%;text-align: left;height: 53px;line-height: 70px;margin-left: 22px;">
        <span style="background-color: #F5F5F5;padding: 6px 52px;">每篇文档页数</span>
        <span style="text-align: left;margin-left: 30px">{{fileCount}}</span>
      </div>
      <div style="width: 100%;height: 260px">
        <div style="float: left;width: 50%">
          <div style="width: 100%;text-align: left;height: 53px;line-height: 70px;margin-left: 22px;">
            <span style="background-color: #F5F5F5;padding: 6px 32px;">充值翻译文档总页数</span>
            <span style="text-align: left;margin-left: 30px">{{count}}</span>
          </div>
          <div style="width: 100%;text-align: left;height: 53px;line-height: 70px;margin-left: 22px;">
            <span style="background-color: #F5F5F5;padding: 6px 32px;">剩余翻译文档总页数</span>
            <span style="text-align: left;margin-left: 30px">{{count-countRemain}}</span>
          </div>
          <div style="width: 100%;text-align: left;height: 53px;line-height: 70px;margin-left: 22px;">
            <span style="background-color: #F5F5F5;padding: 6px 39px;">每日翻译文档页数</span>
            <span style="text-align: left;margin-left: 30px">{{dayCount}}</span>
          </div>
          <div style="width: 100%;text-align: left;height: 53px;line-height: 70px;margin-left: 22px;">
            <span style="background-color: #F5F5F5;padding: 6px 25px;">今日剩余翻译文档页数</span>
            <span style="text-align: left;margin-left: 30px">{{dayCount-dayCountRemain}}</span>
          </div>
          <div style="width: 100%;text-align: left;height: 53px;line-height: 70px;margin-left: 22px;">
            <span style="background-color: #F5F5F5;padding: 6px 18px;">子账号每日翻译文档页数</span>
            <span style="text-align: left;margin-left: 30px">{{childTranslateCountDay}}</span>
          </div>
        </div>
        <div style="float: right;width: 50%">
          <div style="width: 100%;text-align: left;height: 53px;line-height: 70px;margin-left: 22px;">
            <span style="background-color: #F5F5F5;padding: 6px 29px;">充值转换文档总页数</span>
            <span style="text-align: left;margin-left: 30px">{{convertCount}}</span>
          </div>
          <div style="width: 100%;text-align: left;height: 53px;line-height: 70px;margin-left: 22px;">
            <span style="background-color: #F5F5F5;padding: 6px 30px;">剩余转换文档总页数</span>
            <span style="text-align: left;margin-left: 30px">{{convertCount-countConvertRemain}}</span>
          </div>
          <div style="width: 100%;text-align: left;height: 53px;line-height: 70px;margin-left: 22px;">
            <span style="background-color: #F5F5F5;padding: 6px 38px;">每日转换文档页数</span>
            <span style="text-align: left;margin-left: 30px">{{convertCountDay}}</span>
          </div>
          <div style="width: 100%;text-align: left;height: 53px;line-height: 70px;margin-left: 22px;">
            <span style="background-color: #F5F5F5;padding: 6px 25px;">今日剩余转换文档页数</span>
            <span style="text-align: left;margin-left: 30px">{{convertCountDay-dayCountConvertRemain}}</span>
          </div>
          <div style="width: 100%;text-align: left;height: 53px;line-height: 70px;margin-left: 22px;">
            <span style="background-color: #F5F5F5;padding: 6px 19px;">子账号每日转换文档页数</span>
            <span style="text-align: left;margin-left: 30px">{{childConvertCountDay}}</span>
          </div>
        </div>
      </div>
      <div style="width: 100%;text-align: left;height: 53px;line-height: 70px;margin-left: 22px;">
        <span style="background-color: #F5F5F5;padding: 6px 67px;">是否试用</span>
        <span style="text-align: left;margin-left: 30px">{{isTrial?'是':'否'}}</span>
      </div>
      <div style="width: 100%;text-align: left;height: 53px;line-height: 70px;margin-left: 22px;">
        <span style="background-color: #F5F5F5;padding: 6px 67px;">是否冻结</span>
        <span style="text-align: left;margin-left: 30px">{{isFreeze?'是':'否'}}</span>
      </div>
      <div v-if="powerType==='GTime'||isTrial"
           style="width: 100%;text-align: left;height: 53px;line-height: 70px;margin-left: 22px;">
        <span style="background-color: #F5F5F5;padding: 6px 74px;">有效期</span>
        <span style="text-align: left;margin-left: 30px">{{valTime}}</span>
      </div>
      <div v-if="loginModel==='IP自动登录'"
           style="width: 100%;text-align: left;height: 53px;line-height: 70px;margin-left: 22px;">
        <span style="background-color: #F5F5F5;padding: 6px 74px;">IP范围</span>
        <span style="text-align: left;margin-left: 30px">
          <el-input
            disabled
            style="width: 74%;"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="请输入内容"
            v-model="ipScope">
          </el-input>
        </span>
      </div>
    </el-main>
  </div>
</template>

<script>
  export default {
    name: "Info",
    data() {
      return {
        userId: '',
        userName: '',
        loginModel: '',
        ipScope: '',
        powerType: '',
        valTime: '',
        isTrial: false,
        isFreeze: false,
        balance: 0,
        count: 0,
        countRemain: 0,
        fileCount: 0,
        dayCount: 0,
        dayCountRemain: 0,
        childTranslateCountDay: 0,
        convertCount: 0,
        convertCountDay: 0,
        childConvertCountDay: 0,
        countConvertRemain: 0,
        dayCountConvertRemain: 0,
      }
    },
    created() {
      this.info()
    },
    methods: {
      info() {
        const that = this;
        this.$http.post('/api/info').then(res => {
          let data = res.data;
          if (data) {
            that.count = data.count
            that.countRemain = data.countRemain
            that.fileCount = data.fileCount
            that.ipScope = data.ipScope
            that.powerType = data.powerType
            that.userId = data.userId
            that.userName = data.userName
            that.loginModel = data.loginModel
            that.isTrial = data.isTrial
            that.isFreeze = data.isFreeze
            that.balance = data.balance
            that.dayCount = data.dayCount
            that.valTime = data.valTime
            that.dayCountRemain = data.dayCountRemain
            that.childTranslateCountDay = data.childTranslateCountDay
            that.convertCount = data.convertCount
            that.convertCountDay = data.convertCountDay
            that.childConvertCountDay = data.childConvertCountDay
            that.countConvertRemain = data.countConvertRemain
            that.dayCountConvertRemain = data.dayCountConvertRemain
            localStorage.setItem("childTranslateCountDay", data.childTranslateCountDay)
          }
        }).catch(err => {
        })
      },
    }
  }
</script>

<style scoped>
  #recharge-div-one {
    background: #FFFFFF;
    overflow: hidden;
    margin-bottom: 20px;
  }

  #recharge-div {
    background: #FFFFFF;
    overflow: hidden;
    height: 760px;
  }

  #record-div {
    margin-top: 20px;
    height: 700px !important;
    background: #FFFFFF;
  }

  .title-font {
    width: 96px;
    height: 24px;
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #949494;
    margin-top: 27px;
  }

  .line-div {
    width: 98px;
    height: 1px;
    background: #DDDDDD;
  }

  #select-div {
    height: 211px;
    /*width: 890px;*/
    /*border: 1px solid #DDDDDD;*/
  }

  .select-frame {
    float: left;
    margin-left: 42px;
    width: 150px;
    height: 180px;
    background-color: white;
    border: 2px solid #DFB465;
    border-radius: 10px;
    cursor: pointer;
  }

  .select-frame-active {
    background: #fcf5eb !important;
  }

  .select-font-1 {
    height: 25px;
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #27314B;
    margin-top: 15px;
  }

  .select-font-2 {
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #DFB465;
    text-align: center;
    padding: 20px;
  }

  .select-font-3 {
    height: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #8F94A7;
    text-align: center;
  }
</style>
