<template>
  <el-container style="background: #F5F5F5;">
    <el-header style="height: 87px;background: #FFFFFF;width: 100%">
      <center>
        <div style="width: 1200px">
          <a @click="clickHanler('/index')" href="javascript:void(0)">
            <img src="../assets/logo.png" alt="" style=" width: 150px;height: 40px;margin-top: 20px;float: left">
          </a>
          <div style="    float: right; margin-top: 15px;">
            <el-menu v-show="this.$route.path!=='/agreement'" :class="'el-menu-index'"
                     :active-text-color="'#184687'"
                     :text-color="'black'"
                     :default-active="this.$route.name==='DocView'?'/list':this.$route.path"
                     mode="horizontal">
              <el-menu-item class="user-right" style=" margin-right: -30px;
    margin-top: -5px;">
          <span class="el-dropdown-link" v-if="!showloginOut">
              <span
                style="
                    font-size: 14px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: inline-block;
                    width: 86px;
                    overflow: hidden;
                    margin-right: 14px;
                    height: 36px;
                    background: #f5f5f500;
                    border: 1px solid #184687;
                    border-radius: 3px;
                    line-height: 2.5;
                    text-align: center;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #184687;" @click="showIframe('/login')">{{userInfo}}</span>
            </span>
                <el-dropdown @command="logOut" v-if="showloginOut">
            <span class="el-dropdown-link">
              <span :style="'color:black'"
                    <span :style="'color:black'"
                          style="
                    font-size: 14px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: inline-block;
                    max-width: 150px;
                    overflow: hidden;
                    margin-right: 14px;" :title="userInfo">{{userInfo}}</span>
            </span>
                  <el-dropdown-menu v-if="showloginOut" slot="dropdown">
                    <el-dropdown-item style="z-index: 1;font-weight: 400">退出</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-menu-item>
              <el-menu-item class="user-right" style="    margin-left: 30px;margin-top:-5px"  @click="clickHanler('/help')">
                <div class="icon-help"></div>
                <span style="width: 27px;
            height: 15px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 30px;
            ">帮助</span>
              </el-menu-item>
            </el-menu>
          </div>
        </div>
      </center>
    </el-header>
    <div class="jgtbg">
      <div v-if="parentName!==''" style="font-size: 30px;font-family: Source Han Sans CN;font-weight: 400;color: #FFFFFF;    position: absolute;
    left: 50%;transform: translate(-50%, 55px);">
        欢迎{{parentName}}管理员
      </div>
    <center><el-container style="width: 1200px;height: 800px;margin-top: 126px;">
      <el-aside id="menu-div">
        <el-menu default-active="/info">
          <el-menu-item index="/chars" @click="to('/chars')">
            <img src="../assets/xinxi.png" style="width: 44px;height: 44px" alt=""/>
            <span style="margin-left: 12px" >报表</span>
          </el-menu-item>
          <el-menu-item index="/info" @click="to('/info')">
            <img src="../assets/xinxi.png" style="width: 44px;height: 44px" alt=""/>
            <span style="margin-left: 12px" >账号信息</span>
          </el-menu-item>
          <el-menu-item index="/list" @click="to('/list')">
            <img src="../assets/liebiao.png" style="width: 44px;height: 44px" alt=""/>
            <span style="margin-left: 12px" >子账号列表</span>
          </el-menu-item>
          <el-menu-item index="/count"  @click="to('/count')">
            <img src="../assets/tongji.png" style="width: 44px;height: 44px" alt=""/>
            <span style="margin-left: 12px">统计分析</span>
          </el-menu-item>
          <el-menu-item index="/map" @click="to('/map')">
            <img src="../assets/yuliao.png" style="width: 44px;height: 44px" alt=""/>
            <span style="margin-left: 12px" >语料字典</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main id="main-div" style="width: 890px;overflow:hidden;height: 800px">
        <router-view></router-view>
      </el-main>
    </el-container>
    </center>
    </div>
    <el-footer id="foot-div" :class="'footer'" v-if="this.$route.name!=='DocView'">
      <span>© 2020&nbsp;北京容智科技发展有限公司</span>
      <span>&nbsp;&nbsp;&nbsp;京ICP备19036872号-3  All rights reserved.&nbsp;&nbsp;&nbsp;</span>
      <img src="../assets/download.png" alt="" style="transform: translate(1px, 3px);">
      <span>&nbsp;&nbsp;京公网安备&nbsp;11011402010815号</span>
    </el-footer>
  </el-container>
</template>

<script>
  import {getTest} from '../api/test.js'

  export default {
    name: 'Index',
    data() {
      return {
        payCenterDialogVisible: false,
        url: '/login',
        activeIndex: '1',
        userInfo: '登录/注册',
        iframeState: false,
        showloginOut: false,
        showHeader: true,
        parentName:""
      }
    },
    created() {
      this.getUserInfo();
    },
    methods: {

      logOut(){
        this.$cookie.set('content','');
        this.$cookie.set('token','');
        this.$router.push('/login')
      },
      getUserInfo(){
        let user = this.$cookie.get('content');
        if (!user) {
          return
        }
        const userJson = JSON.parse(user);
        const Base64 = require('js-base64').Base64;
        if (userJson.type === 'GroupAdmin') {
          let realName = '';
          if (userJson.realName !== '' && userJson.realName !== undefined) {
            realName = Base64.decode(userJson.realName);
          } else {
            realName = userJson.name;
          }
          if(userJson.parentName !== '' && userJson.parentName !== undefined){
            this.parentName = Base64.decode(userJson.parentName);
          }else {
            this.parentName = realName;
          }
          this.userInfo = realName;
          this.showloginOut = true;
        }
      },
      to(path) {
        console.log(path)
        this.$router.push(path)
      },
      clickHanler(url) {
        let newUrl = process.env.VUE_APP_INDEX + url
        window.open(newUrl, "_self");
      },
      test() {
        getTest().then(testResponse => {
          alert(testResponse.data)
        })
      }
    }
  }
</script>

<style scoped>
  .jgtbg{
    background: url("../assets/JG-tbg.jpg") no-repeat;
  }
  #menu-div  .el-menu-item {
    background-color: white;
    border-left: 4px white solid;
    height: 60px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 55px;
    text-align: left;
    margin-bottom: 15px;
  }
  #menu-div  .el-menu-item.is-active {
    background-color: #F3F6FB;
    border-left: 4px #F3F6FB solid;
    height: 60px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 55px;
    text-align: left;
  }
  #menu-div{
    border: 1px solid #dddddd;
  }
  .footer {
    background-color: #184687;
    color: #ffffff;
    text-align: center;
    height: 140px !important;
    line-height: 1000%;
    float: left;
    width: 100%;
    font-size: 14px;
  }
  .submenuslogin {
    margin-top: -2px !important;
    margin-left: 40px !important;
  }
  /deep/ .el-menu--horizontal > .el-menu-item {
    float: left;
    height: 72px;
    line-height: 60px;
    margin: 0;
  }

  .user-right {
    font-size: 16px !important;
  }

  .el-menu--horizontal > .el-menu-item.user-right.is-active {
    border-bottom: 3px solid #18468700 !important;
  }

  .icon-help::after {
    content: '？';
    color: #fff;
    font-size: 14px;
    font-weight: bold;
  }

  .icon-help {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    background-color: #666666;
    border-radius: 50%;
    margin-right: 5px
  }

  /deep/ .el-menu.el-menu--horizontal {
    border-bottom: solid 0px #e6e6e6;
  }
  .el-menu-item-index {
    line-height: 60px!important;
    margin-right: 30px !important;
    font-size: 14px !important;
    height: 100% !important;
  }
  .el-menu-item-indexs {
    line-height: 60px!important;
    font-size: 14px !important;
    height: 100% !important;
    margin-right: 50px !important;
  }
  .el-header-index {
    width: 100%;
    height: 80px !important;
    background-color: white;
    text-align: -webkit-right;
  }

  .el-header-index .img {
    width: 150px;
    height: 40px;
  }

  #menu-div {
    margin-top: 21px;
    width: 290px;
    height: 760px;
    background: #FFFFFF;
    float: left;
  }

  #main-div {
    height: 926px;
    padding-top: 21px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
  }

  #foot-div {
    height: 140px !important;
    background: #194687;
    text-align: center;
  }

  #foot-font {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 32px;
  }
</style>
