import request from 'axios'

export function getTest () {
  return request({
    url: '/test',
    method: 'post',
    data: {
      data: 'hhhhh',
      num: 232323
    }

  })
}
export function sendRecharge () {
  return request({
    url: '/test',
    method: 'post',
    data: {
      data: 'hhhhh',
      num: 232323
    }

  })
}
// params: {
//     data: 'hhhhh',
//     num: 232323
// }
