<template>
  <div style="width: 100%;overflow: hidden">
    <el-main id="recharge-div" style="font-size: 14px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #666666;">
      <div>
        <el-table
          :row-class-name="tableRowClassName"
          :header-cell-style="{ 'background': '#d3dee8','font-weight': '400','color': '#666666','font-family':'Source Han Sans CN'}"
          :data="tableData"
          style="width: 100%;">
          <el-table-column
            align="center"
            type="index"
            label="序号"
            width="80"
            :index="indexMethod">
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            align="center"
            prop="userId"
            label="子账号ID"
            width="180">
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            align="center"
            prop="userName"
            label="子账号用户名">
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            align="center"
            prop="current"
            label="子账号并发数">
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            align="center"
            prop="dayCount"
            label="子账号翻译数上限/天">
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="60">
            <template slot-scope="scope">
              <el-button @click="handleClick(scope.row)" type="text" size="small">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: right;margin-top: 20px;" v-if="listTotal!==0&&listTotal>10">
        <el-pagination
          background
          layout="prev, pager, next"
          :page-size="10"
          :total="listTotal"
          @prev-click="prevClick"
          @next-click="nextClick"
          @current-change="currentChange"
        >
        </el-pagination>
      </div>
    </el-main>
    <el-dialog :title="title" :visible.sync="dialogFormVisible" style="text-align: left">
      <el-form :model="form">
        <el-form-item label="并发数" :label-width="formLabelWidth">
          <el-input type="number" v-model="form.current" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateCurrent()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "List",
  data() {
    return {
      title: '',
      dialogFormVisible: false,
      form: {
        userId:"",
        current: 0,
      },
      formLabelWidth: '120px',
      tableData: [],
      userId: '',
      userName: '',
      loginModel: '',
      ipScope: '',
      powerType: '',
      valTime: '',
      isTrial: false,
      isFreeze: false,
      balance: 0,
      dayCount: 0,
      dayCountRemain: 0,
      childTranslateCountDay: 0,
      listTotal: 0,
      currentPage: 0,
      pageSize: 10,
    }
  },
  created() {
    this.getRechargeOrder(0, 10)
  },
  methods: {
    updateCurrent(){
      const that=this;
      this.$http.post('/api/updateCurrent', {
        "current": this.form.current,
        "userId": this.form.userId
      }).then(function (response) {
        debugger
        if (response.data) {
         if(response.data==="success"){
           that.dialogFormVisible = false;
           that.$message.success("修改成功");
           that.getRechargeOrder(0, 10);
           return
         }
          that.$message.error("修改失败");
        }
      }).catch(function (error) {
      });

    },
    handleClick(row) {
      this.title = "修改账号:" + row.userId
      this.dialogFormVisible = true;
      this.form.current = row.current;
      this.form.userId = row.userId;
    },
    indexMethod(index) {
      if (this.currentPage === 0) {
        return (this.currentPage) * this.pageSize + index + 1;
      }
      return (this.currentPage - 1) * this.pageSize + index + 1;
    },
    nextClick(val) {
      this.getRechargeOrder(val, 10)
    },
    prevClick(val) {
      this.getRechargeOrder(val, 10)
    },
    currentChange(val) {
      this.getRechargeOrder(val, 10)
    },
    getRechargeOrder(currentPage, pageSize) {
      this.currentPage = currentPage
      this.pageSize = pageSize
      const that = this;
      this.$http.post('/api/list', {
        "currentPage": currentPage,
        "pageSize": pageSize
      }).then(function (response) {
        if (response.data) {
          that.listTotal = response.data.totalCount;
          that.tableData = [];
          response.data.users.forEach((item, index) => {
            const data = {}
            data.userId = item.name
            data.userName = item.realname || "-"
            data.current = item.concurrency
            data.dayCount = localStorage.getItem("childTranslateCountDay")
            that.tableData.push(data)
          });
        }
      }).catch(function (error) {
      });
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 === 0) {
        return 'warning-row';
      } else {
        return 'success-row';
      }
      return '';
    }
  }
}
</script>
<style>
.el-table td {
  padding: 7px 0;
}

.el-table .warning-row {
  background: #FFFFFFFF;
}

.el-table .success-row {
  background: #F2F5F8FF;
}
</style>
<style scoped>

#recharge-div-one {
  background: #FFFFFF;
  overflow: hidden;
  margin-bottom: 20px;
}

#recharge-div {
  background: #FFFFFF;
  overflow: hidden;
  height: 760px;
}

#record-div {
  margin-top: 20px;
  height: 700px !important;
  background: #FFFFFF;
}

.title-font {
  width: 96px;
  height: 24px;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #949494;
  margin-top: 27px;
}

.line-div {
  width: 98px;
  height: 1px;
  background: #DDDDDD;
}

#select-div {
  height: 211px;
  /*width: 890px;*/
  /*border: 1px solid #DDDDDD;*/
}

.select-frame {
  float: left;
  margin-left: 42px;
  width: 150px;
  height: 180px;
  background-color: white;
  border: 2px solid #DFB465;
  border-radius: 10px;
  cursor: pointer;
}

.select-frame-active {
  background: #fcf5eb !important;
}

.select-font-1 {
  height: 25px;
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #27314B;
  margin-top: 15px;
}

.select-font-2 {
  font-size: 40px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #DFB465;
  text-align: center;
  padding: 20px;
}

.select-font-3 {
  height: 20px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #8F94A7;
  text-align: center;
}
</style>
